














































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import draggable from 'vuedraggable';
import Multiselect from 'vue-multiselect';
import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { getComponent, groupBy } from '@/utils/helpers';
import tasqsListModule from '@/store/modules/tasqsListModule';
// import DataLoading from '@/lib/mixins/dataLoading';
import { currentDate } from '@/utils/dates';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import tasqActionsModule from '@/store/modules/tasqActionsModule';
import { ALL_TASQS_LIST_ITEM } from '@/lib/constants';
import { getNameByEmail } from '@/utils/users';
import accountModule from '@/store/modules/accountModule';
import scheduleModule from '@/store/modules/scheduleModule';
import workflowModule from '@/store/modules/workflowModule';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    TasqsVerticalList: () => getComponent('tasqs/TasqsVerticalList'),
    TasqSignal: () => getComponent('tasqs/TasqSignal'),
    SpinnerLoader: () => getComponent('loaders/SpinnerLoader'),
    TasqStatsCard: () => getComponent('tasqs/TasqStatsCard'),
    TasqItemKanban: () => getComponent('tasqs/TasqItemKanban'),
    TasqsDetails: () => getComponent('tasqs/TasqsDetails'),
    draggable,
    Multiselect,
  },
})
export default class Kanban extends mixins() {
  dataLoading = false

  selectedUsernames: any = []

  typesOptions = [] as any;

   isActionNotPerform = false;

  showFilterCycleTimeMenu = false;

  showRouteMenuCloseBtn = false;

  showFilterRouteMenu = false;

  showFilterDefermentMenu = false;

  showDefermentMenuCloseBtn = false

  showFilterJobTypeMenu = false;

  showFilterUserMenu = false;

  showTeamMenuCloseBtn = false;

  showFilterTeamMenu =false;

  hideWellDetail = false;

  activeMoveTasqID: any = null;

  showCycleTimeMenuCloseBtn = false;

  showJobTypeMenuCloseBtn = false

  showUserMenuCloseBtn = false;

  hideSignals = false;

  cycleTimeDays: any = null;

  filterType = '';

  showModal = false;

  defermentDays: any = null;

  showFilterMenu = false;

  selectedRoutes: any = [];

  selectedTeams: any = []

  cycleTimeGreaterThanFilter: any = null;

  cycleTimeLessThanFilter: any = null;

  unitsGreaterThanFilter: any = null;

  unitsLessThanFilter: any = null;

  draggableData: {
    username: string,
    email: string,
    tasqs: TasqJob[],
    new: TasqJob[],
    inProgress: TasqJob[],
	waitingOn: TasqJob[],
    scheduled: TasqJob[],
    resolved: TasqJob[],
  }[] = [];

  get currentDate() {
    return currentDate();
  }

  nameWithDays(name) {
    return `${name} ${name === '1' ? 'Day' : 'Days'}`;
  }

  nameWithUnits(name) {
    return `${name}`;
  }

  get tasqs(): TasqJob[] {
    return [...tasqsListModule.tasqList, ...tasqsListModule.completedTasqList];
  }

  get teams() {
    return workflowModule.teams;
  }

  get activeCycleTimeDays() {
    return tasqsListModule.cycleTimeDays;
  }

  get activeDefermentDays() {
    return tasqsListModule.defermentDays;
  }

  get activeTeams() {
    return tasqsListModule.activeFilterTeam;
  }

  get activeRoutes() {
    return tasqsListModule.activeFilterRoute;
  }

  get kanbanTasq() {
    return tasqsListModule.kanbanSelectedTasq;
  }

  checkMove(event) {
    const { draggedContext } = (event);
    this.activeMoveTasqID = (draggedContext.element.id);
    console.log(event.from.id);
    console.log(event.to.id);
  }

  closeFilterUserMenu() {
    this.showFilterUserMenu = false;
  }

  closeFilterRouteMenu(){
    this.showFilterRouteMenu = false;
  }

  closeFilterCycleTimeMenu() {
    this.showFilterCycleTimeMenu = false;
  }

  closeFilterDefermentMenu() {
    this.showFilterDefermentMenu = false;
  }

  closeFilterTeamMenu() {
    this.showFilterTeamMenu = false;
  }

  closeFilterJobTypeMenu() {
    this.showFilterJobTypeMenu = false;
  }

  checkAdd(event) {
    console.log(event.from.id);
    console.log(event.to.id);
    this.sleep(300).then(async () => {
      if (event.to.id.split('-')[1] !== event.from.id.split('-')[1]) {
        this.reasignTasq({ comment: '', person: event.to.id.split('-')[1], id: this.activeMoveTasqID });
        this.$eventBus.$emit('SHOW_ALERT', 'Successfully reassigned!');
      } else if (this.activeMoveTasqID && event.from.id.split('-')[0] === 'waitingOn') {
        console.log('unsnooze');
        await tasqActionsModule.unsnoozeJob(this.activeMoveTasqID);
        this.hideWellDetail = false;
        tasqsListModule.setActiveTasq(this.activeMoveTasqID || '');
        tasqsListModule.setKanbanSelectedTasq(this.activeMoveTasqID || '');
        this.showModal = true;
      } else if (this.activeMoveTasqID && event.to.id.split('-')[0] === 'waitingOn') {
        this.hideWellDetail = true;
        tasqsListModule.setActiveTasq(this.activeMoveTasqID || '');
        tasqsListModule.setKanbanSelectedTasq(this.activeMoveTasqID || '');
        this.showModal = true;
      // this.$refs.childRef?.prepareAction('reassign');
      } else if (this.activeMoveTasqID) {
      // this.hideSignals = true;
        this.hideWellDetail = false;
        tasqsListModule.setActiveTasq(this.activeMoveTasqID || '');
        tasqsListModule.setKanbanSelectedTasq(this.activeMoveTasqID || '');
        this.showModal = true;
      // this.$refs.childRef?.prepareAction('reassign');
      }
    });
  }

  get activeTasq() {
    return tasqsListModule.activeTasq;
  }

  get activeTasqsCount() {
    return this.tasqs.filter((i) => i.stepOneCompleted && !i.completed).length;
  }

  get allTypes() {
    return tasqsListModule.allPredictionTypes;
  }

  get availableUsers() {
    return accountModule.reassignmentList.map((i) => ({
      // @ts-ignore
      text: i.name,
      value: i.email,
      color: this.randomBackground(),
    }));
  }

  closeDialog() {
    this.showModal = false;
    console.log('close dialog');
  }

  get jobTypeFilter() {
    return tasqsListModule.jobTypeFilter;
  }

  randomBackground() {
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += Math.floor(Math.random() * 10);
    }
    return color;
  }

  get completedTasqCount() {
    return this.tasqs.filter((i) => i.completed).length;
  }

  get notStartedCount() {
    return this.tasqs.length - this.activeTasqsCount - this.completedTasqCount;
  }

  get tasqsJobTypes() {
    return [...new Set(this.tasqs.map((i) => i.overriddenName))];
  }

  get usernames() {
    return tasqsListModule.usernamesList;
  }

  get tasqsJobTypesData() {
    return this.tasqsJobTypes.map(
      (i) => this.tasqs.filter((t) => t.overriddenName === i).length,
    );
  }

  isScheduledTasq(tasq) {
	 return tasq.predictionType === 'scheduled job' || tasq?.Role === 'Workover';
  }

  isWaitingOnTasq(tasq) {
    for (let x = 0; x < tasqsListModule.waitingTasqList.length; x++) {
      // @ts-ignore
      if (tasqsListModule.waitingTasqList[x].snoozed != null && tasqsListModule.waitingTasqList[x].snoozed.WaitUntilReason.includes('Setpoint accepted')) {
        return false;
      }
      if (tasqsListModule.waitingTasqList[x].id == tasq.id) {
        return true;
      }
    }
    return false;
  }

  selectUsername(users) {
    // this.dataLoading = true;
    tasqsListModule.setUsernamesBulkList(users);
    // await tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM);
    localStorage.setItem('kanbanUsernamesFilter', JSON.stringify(tasqsListModule.usernamesList));
    // this.dataLoading = false;
    // this.setDraggableData();
  }

  getAcceptedSetpointTasqs(username) {
	 const results: any[] = [];

    for (let x = 0; x < tasqsListModule.waitingTasqList.length; x++) {
      if (tasqsListModule.waitingTasqList[x].username != username) {
        continue;
      }
      // @ts-ignore
      if (tasqsListModule.waitingTasqList[x].snoozed != null && tasqsListModule.waitingTasqList[x].snoozed.WaitUntilReason.includes('Setpoint accepted') && !tasqsListModule.waitingTasqList[x].completed) {
        results.push(tasqsListModule.waitingTasqList[x]);
      }
    }
    return results;
  }

  getTasqsInProgress(tasqs) {
    const results: any[] = [];
    for (let x = 0; x < tasqs.length; x++) {
      const tasq = tasqs[x];
      if (tasq.lastResponseTime.length && !tasq.completed && !this.isScheduledTasq(tasq) && !this.isWaitingOnTasq(tasq)) {
        results.push(tasq);
      }
    }
	  return results;
  }

  getNewTasqs(tasqs) {
    const results: any[] = [];
    for (let x = 0; x < tasqs.length; x++) {
      const tasq = tasqs[x];
      if (!tasq.lastResponseTime.length && !tasq.completed && !this.isScheduledTasq(tasq) && !this.isWaitingOnTasq(tasq)) {
        results.push(tasq);
      }
    }
	  return results;
  }

  getResolvedTasqs(tasqs, username) {
    const results: any[] = [];
    for (let x = 0; x < tasqs.length; x++) {
      const tasq = tasqs[x];
      if (tasq.completed) {
        results.push(tasq);
      }
    }
    const setpointResults = this.getAcceptedSetpointTasqs(username);
    results.push(...setpointResults);
	  return results;
  }

  closeFilterDialog() {
    this.showFilterMenu = false;
  }

  async applyFiltering() {
    this.dataLoading = true;
    this.showFilterMenu = false;
    this.showFilterCycleTimeMenu = false;
    this.showFilterDefermentMenu = false;
    this.showFilterJobTypeMenu = false;
    this.showFilterUserMenu = false;
    this.showFilterTeamMenu = false;
    this.dataLoading = true;
    tasqsListModule.setCycleTimeDaysFilter(this.cycleTimeDays);
    tasqsListModule.setDefermentDaysFilter(this.defermentDays);
    tasqsListModule.setActiveTeamFilter(this.selectedTeams);
    tasqsListModule.setActiveRouteFilter(this.selectedRoutes)
    tasqsListModule.setJobTypeFilter(this.typesOptions);

    this.sleep(100).then(async () => {
      this.selectUsername(this.selectedUsernames.map((u) => u.value));
      localStorage.setItem('kanbanCycleFilter', this.cycleTimeDays);
      localStorage.setItem('kanbanDefermentFilter', this.defermentDays);
      localStorage.setItem('kanbanJobTypeFilter', JSON.stringify(this.typesOptions));

      this.filterType = '';

      await tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM);
      this.dataLoading = false;

      this.setDraggableData();
    });
  }

  async resetFiltering() {
    this.dataLoading = true;
    if (this.filterType === 'users') {
      this.selectedUsernames = [];
      this.selectUsername([]);
    } else if (this.filterType === 'jobType') {
      this.typesOptions = [];
      await this.applyFiltering();
    } else if (this.filterType === 'cycleTime') {
      this.cycleTimeDays = null;
      await this.applyFiltering();
    } else if (this.filterType === 'deferment') {
      this.defermentDays = null;
      await this.applyFiltering();
    } else if (this.filterType === 'routes') {
      this.selectedRoutes = [];
    } else if (this.filterType === 'teams') {
      this.selectedTeams = [];
    }
  }

  reasignTasq(action: any) {
    tasqActionsModule.mutateTasqReassign({ comment: '', person: action.person, id: action.id });
  }

  getWaitinOnTasqList(username) {
	  const results: any[] = [];
	  for (let x = 0; x < tasqsListModule.waitingTasqList.length; x++) {
		  if (tasqsListModule.waitingTasqList[x].username == username) {
			  // @ts-ignore
        if (tasqsListModule.waitingTasqList[x].snoozed != null && tasqsListModule.waitingTasqList[x].snoozed.WaitUntilReason.includes('Setpoint accepted')) {
          return false;
        }
        if (!tasqsListModule.waitingTasqList[x].completed) {
          results.push(tasqsListModule.waitingTasqList[x]);
        }
		  }
	  }
	  return results;
  }

  setDraggableData() {
    // @ts-ignore
    this.draggableData = Object.entries(groupBy(this.tasqs, 'username')).map((i: any) => ({
      username: getNameByEmail(i[0]),
      email: i[0],
      tasqs: i[1] as TasqJob[],
      new: this.getNewTasqs(i[1] as TasqJob[]),
      inProgress: this.getTasqsInProgress(i[1] as TasqJob[]), // i[1].filter(i => i.lastResponseTime.length && !i.completed),
	    waitingOn: this.getWaitinOnTasqList(i[0]),
      scheduled: i[1].filter((i) => i.predictionType === 'scheduled job' || i?.Role === 'Workover'),
      resolved: this.getResolvedTasqs(i[1] as TasqJob[], i[0]),
    }));
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  get enableRoutes() {
    return scheduleModule.enabledRoutes.map((r) => r.Route);
  }

  async created() {
    tasqsListModule.setActivePage('Kanban');
    tasqsListModule.setActiveTasq('');
    tasqsListModule.setKanbanSelectedTasq('');
    this.dataLoading = true;
    await this.getKanbanData();
    await scheduleModule.getEnabledRoutes();
    await workflowModule.listTeams();
    this.dataLoading = false;
  }

  async getKanbanData() {
    this.hideWellDetail = false;
    this.hideSignals = false;

    this.showModal = false;

    await accountModule.getReassignmentList();
    await tasqsListModule.getWaitList();

    const kanbanCycleFilter = localStorage.getItem('kanbanCycleFilter');
    const kanbanDefermentFilter = localStorage.getItem('kanbanDefermentFilter');
    const kanbanJobTypeFilter = localStorage.getItem('kanbanJobTypeFilter');
    let kanbanUsernamesFilter = localStorage.getItem('kanbanUsernamesFilter');
    console.log(kanbanUsernamesFilter);
    if (kanbanCycleFilter) {
      tasqsListModule.setCycleTimeDaysFilter(kanbanCycleFilter);
      this.cycleTimeDays = tasqsListModule.cycleTimeDays;
    }
    if (kanbanDefermentFilter) {
      tasqsListModule.setDefermentDaysFilter(kanbanDefermentFilter);
      this.defermentDays = tasqsListModule.defermentDays;
    }
    if (kanbanJobTypeFilter) {
      tasqsListModule.setJobTypeFilter(JSON.parse(kanbanJobTypeFilter));
      this.typesOptions = tasqsListModule.jobTypeFilter;
    } else {
      this.typesOptions = [];
      tasqsListModule.setJobTypeFilter([]);
    }

    if (kanbanUsernamesFilter) {
      this.selectedUsernames = [];
      kanbanUsernamesFilter = JSON.parse(kanbanUsernamesFilter);
      this.availableUsers.forEach((i) => {
        if (kanbanUsernamesFilter?.includes(i.value)) {
          this.selectedUsernames.push(i);
        }
      });
      tasqsListModule.setUsernamesBulkList(kanbanUsernamesFilter);
    }

    console.log(tasqsListModule.activePage);

    await tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM);
    // @ts-ignore
    this.setDraggableData();
  }

  beforeDestroy() {
    tasqsListModule.setActivePage('');
    tasqsListModule.setActiveTasq('');
    tasqsListModule.setKanbanSelectedTasq('');
  }

  setActionPerform() {
    this.isActionNotPerform = true;
  }

  @Watch('kanbanTasq')
  async onkanbanTasqChange(id: string) {
    if (id) {
      console.log(id);
      this.showModal = true;
    } else {
      this.showModal = false;
    }
  }

   @Watch('activeTasq')
  async onactiveTasqChange(id: string) {
    console.log(id);
    if (id) {
      this.showModal = true;
      this.isActionNotPerform = false;
    } else {
      this.showModal = false;
      if (!this.isActionNotPerform) {
        this.sleep(3500).then(async () => {
        // this.dataLoading = true;
          await this.getKanbanData();
          this.setDraggableData();
        });
      } else {
        this.setDraggableData();
      }
    }
  }
}
